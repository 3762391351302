import { useAuthenticationContext } from "../context/AuthenticationContext";
import { Navigate } from "react-router-dom";

function ProtectedAdminRoute({ children }) {
  const { authenticationStatus, USER_ROLES } = useAuthenticationContext();

  if (
    authenticationStatus.authenticated &&
    (authenticationStatus.role === USER_ROLES.ADMIN)
  ) {
    return children;
  }

  // Redirect to home page if not authenticated or not an admin
  return <Navigate to="/" replace />;
}

export default ProtectedAdminRoute;