import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import { checkEmailValidity } from "../commons/utilities";
import ModalAlert from "../components/modals/ModalAlert";
import ModalInfo from "../components/modals/ModalInfo";
import { forgotPasswordAPI } from "../api/api";
import classes from "./page.module.css";
import "./login.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const [forgotPasswordResponse, setForgotPasswordResponse] = useState(null);
  const [showSuccessfullMessage, setShowSuccessfullMessage] = useState(false);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (forgotPasswordResponse) {
      setIsBusy(false);
      console.log(
        `Got a new forgotPasswordResponse: ${JSON.stringify(
          forgotPasswordResponse
        )}`
      );
      if (forgotPasswordResponse.success) {
        setShowSuccessfullMessage(true);
      } else {
        let message = `Unable to reset password. Error: ${
          forgotPasswordResponse.errorMessage || "Unknown"
        }.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [forgotPasswordResponse]);

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    console.log(
      `Check validity of form inputs, then call the API..., email: ${email}`
    );

    

    //check validity of restaurantName, user, email
    if (!email || !checkEmailValidity(email)) {
      setIsInvalidEmail(true);
      console.log("Invalid email, returning...");
      //setModalAlertMessage("Invalid parameters, please check the form inputs.");
      //setShowModalAlert(true);
      return;
    }

    console.log("All parameters are valid, call the API...");
    forgotPasswordAPI(email, setForgotPasswordResponse);

  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className="login_container">
          <p className={classes.titleText}>Forgot Your Password..</p>
          {!showSuccessfullMessage ? (
            <form className="login_form" onSubmit={handleSubmitForgotPassword}>

              <div className="login_element">
                <TextField
                  type="text"
                  id="email"
                  className="input_element"
                  label="Your Email"
                  autoComplete="off"
                  onChange={(e) => {
                    setIsInvalidEmail(false);
                    setEmail(e.target.value);
                  }}
                  value={email}
                  error={isInvalidEmail}
                  helperText={isInvalidEmail ? "Invalid Email" : ""}
                  required
                />
              </div>

              <div className="button_div">
                <MyButton
                  variant={"primary"}
                  onClickHandler={handleSubmitForgotPassword}
                  title={"Reset Password"}
                />
              </div>
            </form>
          ) : (
            <div className="login_form">
              <p className="login_element">
                Password reset link sent to your email address ({`${email}`}).
                Please check your email.
              </p>
            </div>
          )}
        </div>

        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => setShowModalAlert(false)}
        />
        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => setShowModalInfo(false)}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default ForgotPassword;