import React, {useState, useEffect} from "react";
import ModalRejection from "./modals/ModalRejection";
import classes from "../pages/page.module.css";
import regReqClasses from "./regRequestCard.module.css";
import MyButton from "./MyButton";

function RegRequestCard({ regRequest, handleAccept, handleReject }) {
  
  const [showRjectionModal, setShowRjectionModal] = useState(false);

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={regReqClasses.reg_request_container}>
          <div className={regReqClasses.reg_request_card}>
            <div className={regReqClasses.reg_request_header}>
              Registeration Request
            </div>
            <div className={regReqClasses.reg_request_main_section}>
              {regRequest ? (
                <>
                  <div className={regReqClasses.reg_request_info}>
                    <div className={regReqClasses.reg_request_info_text}>
                      <p>Username: {regRequest.username}</p>
                    </div>
                    <div className={regReqClasses.reg_request_info_button}>
                      <MyButton
                        title="User Info"
                        onClickHandler={() =>
                          console.log("User Info button clicked - show as a modal1")
                        }
                        variant="secondary"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className={regReqClasses.reg_request_info}>
                    <div className={regReqClasses.reg_request_info_text}>
                      <p>Course: {regRequest.courseShortName}</p>
                      <p>Course id: {regRequest.courseId}</p>
                    </div>
                    <div className={regReqClasses.reg_request_info_button}>
                      <MyButton
                        title="Course Info"
                        onClickHandler={() =>
                          console.log("Course Info button clicked - show as a modal2")
                        }
                        variant="secondary"
                        disabled={false}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div>registeration request is null.</div>
              )}
            </div>
            <div className={regReqClasses.reg_request_footer}>
              <MyButton
                title="Reject"
                onClickHandler={() => setShowRjectionModal(true)}
                variant={"secondary"}
                disabled={false}
              />
              <MyButton
                title="Accept"
                onClickHandler={()=> handleAccept(regRequest)}
                variant={"primary"}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalRejection
        show={showRjectionModal}
        regRequest={regRequest}
        handleReject={handleReject}
        handleClose={() => setShowRjectionModal(false)}
      />

    </div>
  );
}

export default RegRequestCard;
