import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import cardClasses from "./card.module.css";

function Card({ title, imageSrc, imageAlt, text, variant, url }) {
  const navigate = useNavigate();
  const current_location = useLocation();

  //no_url: clicking on the card will not navigate to another page.
  //with_url: clicking on the card will navigate to another (details) page.
  const variants = { no_url: "no_url", with_url: "with_url" };

  const [thisVariant, setThisVariant] = useState(variants.general);

  //determine variant when component mount.
  useEffect(() => {
    if (!variant || !(variant in variants)) {
      setThisVariant(variants.no_url);
    } else {
      setThisVariant(variant);
    }
  }, [variant]);

  useEffect(() => {
    if (!imageSrc && !imageAlt && !title && !text && !variant && !url) {
      return <p className={cardClasses.noContent}>No content available.</p>;
    } else {
      console.log(`Image: ${imageSrc}`);
      console.log(`Title: ${title}`);
      console.log(`Text: ${text}`);
      console.log(`Variant: ${variant}`);
      console.log(`URL: ${url || "No URL"}`);
    }
  }, [imageSrc, imageAlt, title, text, variant, url]);

  return (
    <div
      className={cardClasses.card}
      onClick={() => {
        navigate(
          thisVariant === variants.with_url && url ? `${url}` : current_location
        );
      }}
    >
      {imageSrc && imageAlt ? (
        <div className={cardClasses.image}>
          <img
            src={`${imageSrc}`}
            alt={`${imageAlt}`}
            className={cardClasses.image}
          />
          <h4 className={cardClasses.title}>{title}</h4>
        </div>
      ) : (
        <div className={cardClasses.placeholder}>No Image</div>
      )}

      {/* Display Course Details */}
      <div className={cardClasses.details}>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Card;
