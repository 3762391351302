import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
//import { fetchAuthenticationStatusAPI } from "../api/api.js";
//import { useSessionStorage } from "../hooks/useSessionStorage";
//import { useLocalStorage } from "../hooks/useLocalStorage";
import { fetchRegisterationRequestsAPI } from "../api/api";
import { useAuthenticationContext } from "./AuthenticationContext";

//create empty context
const RegisterationContext = createContext({});

//const coursesStorage = "coursesStore";

export function useRegisterationContext() {
  return useContext(RegisterationContext);
}

export function RegisterationContextProvider({ children }) {
  const { authenticationStatus, USER_ROLES } = useAuthenticationContext();
  const [regRequests, setRegRequests] = useState(null);
  const [getRegRequestsResponse, setGetRegRequestsResponse] = useState(null);

  //check if get courses response is received
  useEffect(() => {
    if (getRegRequestsResponse) {
      console.log(`>>> getRegRequestsResponse is received: <<<<`);
      if (getRegRequestsResponse.success) {
        console.log("getReqRequestsResponse is valid.");
        setRegRequests(getRegRequestsResponse.regRequests);
      } else {
        console.log("Error in getting reg requests from backend >> do nothing");
      }
      setGetRegRequestsResponse(null);
    }
  }, [getRegRequestsResponse]);

  //get general courses from backend after component is mounted
  useEffect(() => {
    if (
      authenticationStatus.authenticated &&
      authenticationStatus.role === USER_ROLES.ADMIN
    ) {
      reFetchRegRequests();
    }
  }, [authenticationStatus]);

  //re-fetch registeration requests
  const reFetchRegRequests = () => {
    fetchRegisterationRequestsAPI(setGetRegRequestsResponse);
  };

  //remove a reg request from regRequests
  const removeRegRequest = (regRequestId) => {
    let newRegRequests = regRequests.filter(
      (regRequest) => regRequest._id !== regRequestId
    );
    console.log(`After removing regRequest: ${regRequestId}, length of regRequests: ${newRegRequests.length}`);
    setRegRequests(newRegRequests);
  };

  return (
    <RegisterationContext.Provider
      value={{
        regRequests,
        reFetchRegRequests,
        removeRegRequest,
      }}
    >
      {children}
    </RegisterationContext.Provider>
  );
}
