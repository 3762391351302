import React, { useEffect } from "react";
import classes from "./page.module.css";

function About() {

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container}>
            <p>About us ...</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
