import React, { useEffect, useState } from "react";
import MyButton from "../components/MyButton";
//import { redirect } from "react-router-dom";
import { generateRandomString } from "../commons/utilities";
import { getLMSLink_API, fetchLMSCoursesAPI } from "../api/api";
import MySpinner from "../components/MySpinner";
import CourseCard from "../components/CourseCard";
import Card from "../components/card/Card";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { useCoursesContext } from "../context/CoursesContext";
import classes from "./page.module.css";
import multipleCardClasses from "../components/multipleCard.module.css";

function LMS() {
  const { authenticationStatus } = useAuthenticationContext();
  const { generalCourses, userCourses } = useCoursesContext();
  const [getLMSLinkResponse, setGetLMSLinkResponse] = useState(null);
  const [isLMSLinkAvailable, setIsLMSLinkAvailable] = useState(false);
  const [lmsLink, setLmsLink] = useState(null);
  const [isLMSLinkError, setIsLMSLinkError] = useState(false);
  const [lmsLinkErrorMessage, setLMSLinkErrorMessage] = useState(null);

  //const [getLMSCoursesResponse, setGetLMSCoursesResponse] = useState(null);
  //const [isWaitingForLMSCourses, setIsWaitingForLMSCourses] = useState(false);
  //const [lmsCourses, setLMSCourses] = useState(null);

  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);
  const [isBusy, setIsBusy] = useState(false);

  const token = process.env.REACT_APP_MOODLE_DOWNLOAD_IMAGES_TOKEN;

  const clientID = "testclient";
  /*let redirectURI =
    process.env.REACT_APP_MODE === "dev"
      ? "http://localhost:3000/login"
      : "https://ediscool.com/login";
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;*/

  //get LMS courses on startup.
  /*useEffect(() => {
    console.log("get LMS courses...");
    setGetLMSCoursesResponse(null);
    setIsWaitingForLMSCourses(true);

    fetchLMSCoursesAPI(setGetLMSCoursesResponse);
  }, []);*/

  //check if LMS courses response has been received.
  /*useEffect(() => {
    if (getLMSCoursesResponse) {
      setIsWaitingForLMSCourses(false);
      if (getLMSCoursesResponse.success) {
        console.log(
          `Got LMS courses: ${JSON.stringify(getLMSCoursesResponse.courses)}`
        );
        setLMSCourses(getLMSCoursesResponse.courses);
      } else {
        console.log(
          `Failed to get LMS courses: ${getLMSCoursesResponse.errorMessage}`
        );
      }
    }
  }, [getLMSCoursesResponse]);*/

  //reload component when authenticationStatus changes
  useEffect(() => {
    console.log(
      `reload page whenever authenticationStatus changes and/or courses`
    );
    setLoggedIn(authenticationStatus.authenticated);
  }, [authenticationStatus, generalCourses, userCourses]);

  useEffect(() => {
    if (getLMSLinkResponse) {
      setIsBusy(false);
      console.log(`getLMSLinkResponse: ${getLMSLinkResponse}`);
      if (getLMSLinkResponse.success && getLMSLinkResponse.url) {
        console.log(`***** LMS URL: ${getLMSLinkResponse.url}`);
        setLmsLink(getLMSLinkResponse.url);
        setIsLMSLinkAvailable(true);
        setIsLMSLinkError(false);
        setLMSLinkErrorMessage(null);

        //console.log(`Redirecting user to: ${getLMSLinkResponse.url}`);
        //window.location.href = getLMSLinkResponse.url;
      } else {
        setIsLMSLinkError(true);
        if (!getLMSLinkResponse.success) {
          console.log(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
          setLMSLinkErrorMessage(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
        } else {
          console.log(`LMS URL is NULL.`);
          setLMSLinkErrorMessage(`LMS URL is NULL.`);
        }
      }
      setGetLMSLinkResponse(null);
    }
  }, [getLMSLinkResponse]);

  const getLMSLink = () => {
    setGetLMSLinkResponse(null);
    setIsLMSLinkAvailable(false);
    setIsLMSLinkError(false);
    getLMSLink_API(authenticationStatus.username, setGetLMSLinkResponse);
    setIsBusy(true);

    return;
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          {loggedIn && (
            <div className={classes.body_sub_container}>
              <h3>This is personal lms space.</h3>
              <MyButton
                variant={"primary"}
                title={"Get LMS Link"}
                onClickHandler={() => {
                  console.log("Get LMS Link");
                  getLMSLink();
                }}
                disabled={isBusy}
              />
              {isLMSLinkAvailable && lmsLink && (
                <MyButton
                  variant={"primary"}
                  title={"Go To LMS"}
                  onClickHandler={() => {
                    console.log(`******** Redirecting user to: ${lmsLink}`);
                    setIsBusy(true);
                    //window.location.href = lmsLink;
                    window.open(lmsLink, "_blank");
                    setIsBusy(false);
                  }}
                  disabled={isBusy}
                />
              )}
              {isLMSLinkError && lmsLinkErrorMessage && (
                <div>{lmsLinkErrorMessage}</div>
              )}
            </div>
          )}
          {loggedIn && (
            <div className={classes.body_sub_container}>
              <span className={classes.sub_container_title}>Your Courses</span>
              <div className={multipleCardClasses.multiple_card_container}>
                {userCourses && userCourses.length > 0 ? (
                  <div
                    className={
                      multipleCardClasses.multiple_card_container_wrapper
                    }
                  >
                    {userCourses.map((course) => (
                      /*<CourseCard
                        course={course}
                        variant="user"
                        key={course.id}
                      />*/
                      <Card
                        title={course.fullname}
                        imageSrc={`${course.image}?token=${token}`}
                        imageAlt={`${course.fullname} Image`}
                        text={`You have completed (${course.progress}%) of this course. Keep it up!`}
                        variant={"no_url"}
                        url={`/lms/${course.shortname}`}
                      />
                    ))}
                  </div>
                ) : (
                  <div>User is not currently enrolled in any courses.</div>
                )}
              </div>
            </div>
          )}
          {loggedIn && (
            <div className={classes.body_sub_container}>
              <span className={classes.sub_container_title}>
                You Maybe Interested In These Courses
              </span>
              <h3>
                This section is for courses offered by EdIsCool that might be of
                particular interest to this user's age, interests, etc.{" "}
              </h3>
            </div>
          )}
          <div className={classes.body_sub_container}>
            <span className={classes.sub_container_title}>
              Courses Offered by EdIsCool
            </span>
            <div className={multipleCardClasses.multiple_card_container}>
              {generalCourses && generalCourses.length > 0 ? (
                <div
                  className={
                    multipleCardClasses.multiple_card_container_wrapper
                  }
                >
                  {generalCourses.map((course) => (
                    /*<CourseCard course={course} key={course.id} />*/
                    <Card
                      title={course.fullname}
                      imageSrc={`${course.image}?token=${token}`}
                      imageAlt={`${course.fullname} Image`}
                      text={course.summary}
                      variant={"with_url"}
                      url={`/lms/${course.shortname}`}
                    />
                  ))}
                </div>
              ) : (
                <div>No courses found.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isBusy && <MySpinner message={"Wait..."} />}
    </div>
  );
}

export default LMS;
