import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import MyButton from "../MyButton";
import modal_classes from "./modal.module.css";

function ModalRejection({ show, regRequest, handleReject, handleClose }) {
  const [note, setNote] = useState(null);

  return (
    <Modal show={show} onHide={handleClose} centered /*dir={i18n.dir()}*/>
      <Modal.Header closeButton className={modal_classes.modalHeader}>
        <Modal.Title className={modal_classes.modalTitle}>
          Reject Enrollment Request For {regRequest.username}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={modal_classes.modalBody}>
          <div className={modal_classes.modalBodyRow}>
            <div className={modal_classes.vertical_element}>
              <div className={modal_classes.horizontal_element}>
                <div className={modal_classes.modalBodyRow_label}>
                  <label htmlFor="note">Note:</label>
                </div>
                <div className={modal_classes.modalBodyRow_input}>
                  <TextField
                    className={modal_classes.modalBodyRow_input_tf}
                    id="note"
                    multiline
                    rows={3}
                    type="text"
                    value={note || ""}
                    placeholder="Enter note"
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={modal_classes.buttonsRow}>
          <MyButton
            variant={"primary"}
            onClickHandler={() => {
              handleReject(regRequest, note);
              setNote(null);
              handleClose();
            }}
            title={"Reject Enrollment"}
          />
          <MyButton
            variant={"secondary"}
            onClickHandler={() => {
              setNote(null);
              handleClose();
            }}
            title={"Cancel"}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRejection;
