import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
//import { fetchAuthenticationStatusAPI } from "../api/api.js";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { fetchJWTStatusAPI, logoutAPI } from "../api/api";

//create empty context
const AuthenticationContext = createContext({});

const authStorage = "ediscoolAuth";

const USER_ROLES = {
  ADMIN: "admin",
  TEACHER: "teacher",
  TEATCHING_ASSISTANT: "teaching_assistant",
  STUDENT: "student",
};

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}

export function AuthenticationContextProvider({ children }) {
  /*const [authenticationStatus, setAuthenticationStatus] = useSessionStorage(
    authStorage,
    {
      authenticated: false,
      username: null,
      role: null,
      errorMessage: null,
    }
  );*/
  const [jwtStatusResponse, setJWTStatusResponse] = useState(null);
  //const [hasAskedForJWTStatus, setHasAskedForJWTStatus] = useState(false);

  const [authenticationStatus, setAuthenticationStatus] = useLocalStorage(
    authStorage,
    {
      authenticated: false,
      username: null,
      email: null,
      userId: null,
      role: null,
      errorMessage: null,
    }
  );

  // Sync with local storage on component mount
  useEffect(() => {
    //const storedAuthStatus = sessionStorage.getItem(authStorage);
    const storedAuthStatus = localStorage.getItem(authStorage);
    if (storedAuthStatus) {
      try {
        const parsedAuthStatus = JSON.parse(storedAuthStatus);
        setAuthenticationStatus(parsedAuthStatus);
      } catch (e) {
        console.error("Failed to parse session storage data:", e);
      }
    }
  }, [setAuthenticationStatus]);

  //check if jwt status response is received
  useEffect(() => {
    if (jwtStatusResponse) {
      console.log(`>>> JWT status response: ${JSON.stringify(jwtStatusResponse)}`);
      if (jwtStatusResponse.success) {
        console.log("JWT is valid.");
        updateAuthenticationStatus({
          authenticated: jwtStatusResponse.authenticated,
          username: jwtStatusResponse.username,
          email: jwtStatusResponse.email,
          userId: jwtStatusResponse.userId,
          role: jwtStatusResponse.role,
          errorMessage: jwtStatusResponse.errorMessage,
        });
      } else {
        console.log("Error in getting jwt status from backend.");
        updateAuthenticationStatus({
          authenticated: false,
          username: null,
          email: null,
          userId: null,
          role: null,
          errorMessage: jwtStatusResponse.errorMessage,
        });
      }
      setJWTStatusResponse(null);
      //setHasAskedForJWTStatus(false);
    }
  }, [jwtStatusResponse]);

  //get authentication status after component is mounted
  useEffect(() => {
    checkJWTStatus();
    /*if (!hasAskedForJWTStatus) {
      checkJWTStatus();
      setHasAskedForJWTStatus(true);
    }*/
  }, []);

  //console.log when authenticationStatus changes
  useEffect(() => {
    console.log(
      `"""""" Authentication status is changed: ${JSON.stringify(
        authenticationStatus
      )}`
    );
  }, [authenticationStatus]);

  /*const getAuthenticationStatus = () => {
    console.error("you sould get authentication status from backend.");
    //fetchAuthenticationStatusAPI(setAuthenticationStatus);
  };*/

  const updateAuthenticationStatus = (authStatus) => {
    setAuthenticationStatus((prev) => {
      return {
        authenticated: authStatus.authenticated
          ? authStatus.authenticated
          : false,
        username: authStatus.username ? authStatus.username : prev.username,
        email: authStatus.email ? authStatus.email : prev.email,
        userId: authStatus.userId ? authStatus.userId : prev.userId,
        role: authStatus.role ? authStatus.role : prev.role,
        errorMessage: authStatus.errorMessage ? authStatus.errorMessage : null,
      };
    });
  };

  const resetAuthentication = () => {
    setAuthenticationStatus({
      authenticated: false,
      username: null,
      email: null,
      userId: null,
      role: null,
      errorMessage: null,
    });
  };

  const checkJWTStatus = () => {
    console.log("you should check JWT status from backend.");
    fetchJWTStatusAPI(setAuthenticationStatus);
  };

  const logoutUser = () => {
    console.log("you should logout user from backend.");
    logoutAPI(setLogoutResponse);
  };

  const setLogoutResponse = (response) => {
    if (response.success) {
      resetAuthentication();
    } else {
      console.error("******* Failed to logout user. ********");
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        authenticationStatus,
        USER_ROLES,
        updateAuthenticationStatus,
        resetAuthentication,
        checkJWTStatus,
        logoutUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
