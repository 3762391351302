import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import MyButton from "../components/MyButton";
//import { redirect } from "react-router-dom";
import { generateRandomString } from "../commons/utilities";
import { enrollInCourseRequestAPI } from "../api/api";
import MySpinner from "../components/MySpinner";
import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import { useCoursesContext } from "../context/CoursesContext";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import classes from "./page.module.css";
import courseClasses from "./course.module.css";

function Course() {
  const { authenticationStatus } = useAuthenticationContext();
  const { findCourseByShortName } = useCoursesContext();
  const [enrollInCourseResponse, setEnrollInCourseResponse] = useState(null);
  const [course, setCourse] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  const { courseName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`Course short name: ${courseName}`);
    let courseFromContext = findCourseByShortName(courseName);
    if (courseFromContext) {
      console.log(`Course ${courseName} found in context.`);
      setCourse(courseFromContext);
    }
  }, []);

  useEffect(() => {
    if (enrollInCourseResponse) {
      setIsBusy(false);
      if (enrollInCourseResponse.success) {
        console.log(
          `Successfully enrolled in course: ${enrollInCourseResponse.message}`
        );
        setModalInfoMessage(enrollInCourseResponse.message);
        setShowModalInfo(true);
      } else {
        console.log(
          `Failed to enroll in course: ${enrollInCourseResponse.errorMessage}`
        );
        setModalAlertMessage(enrollInCourseResponse.errorMessage);
        setShowModalAlert(true);
      }
    }
  }, [enrollInCourseResponse]);

  const handleEnrollInCourse = () => {
    console.log(`Enrolling in course: ${course.shortname}`);
    setIsBusy(true);
    enrollInCourseRequestAPI(
      course.id,
      course.shortname,
      setEnrollInCourseResponse
    );
  };

  const closeModalInfo = () => {
    setShowModalInfo(false);
    navigate(-1);
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={courseClasses.course_details_container}>
          <div className={courseClasses.course_details_card}>
            <div className={courseClasses.course_details_header}>
              <div className="icon" onClick={() => navigate(-1)}>
                <KeyboardReturnIcon />
              </div>
              <div className={courseClasses.course_details_header_tile}>
                {course ? course.fullname : "-"}
              </div>
            </div>
            <div className={courseClasses.course_details_main_section}>
              {course ? (
                <>
                  <img
                    src={`${course.image}?token=e357cc288fd59e90c89e95f011a04c07`}
                    alt={`${course.fullname} Image`}
                    className={courseClasses.course_details_image}
                  />
                  <div className={courseClasses.course_details_text}>
                    {course.summary && (
                      <p className={courseClasses.course_details_summary}>
                        {course.summary}
                      </p>
                    )}
                    {course.startdate && (
                      <div className={courseClasses.course_details_date}>
                        <p>Course start date: {course.startdate}.</p>
                        <p>Course end date: {course.enddate}.</p>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <p className={courseClasses.course_details_summary}>
                  Unable to find course details. Return to previous page.
                </p>
              )}
            </div>
            <div className={courseClasses.course_details_footer}>
              {authenticationStatus.authenticated ? (
                <MyButton
                  title={"Enroll in Course"}
                  onClickHandler={() => {
                    handleEnrollInCourse();
                  }}
                />
              ) : (
                <>
                  <p>login to enroll in course.</p>
                  <MyButton
                    title={"Login"}
                    onClickHandler={() => {
                      navigate("/login");
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isBusy && <MySpinner message={"Wait..."} />}
      <ModalInfo
        show={showModalInfo}
        infoMessage={modalInfoMessage}
        handleClose={closeModalInfo}
      />
      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={() => setShowModalAlert(false)}
      />
    </div>
  );
}

export default Course;
