import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import App from "./App";
import { AuthenticationContextProvider } from "./context/AuthenticationContext";
import { CoursesContextProvider } from "./context/CoursesContext";
import { RegisterationContextProvider } from "./context/RegisterationContext";
import { AppearanceContextProvider } from "./context/AppearanceContext";

if (process.env.REACT_APP_MODE === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppearanceContextProvider>
      <AuthenticationContextProvider>
        <RegisterationContextProvider>
          <CoursesContextProvider>
            <App />
          </CoursesContextProvider>
        </RegisterationContextProvider>
      </AuthenticationContextProvider>
    </AppearanceContextProvider>
  </React.StrictMode>
);
