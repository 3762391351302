import React, { useRef, useState, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
//import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
//import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import MyAutoComplete from "../components/common/MyAutoComplete";
import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import {
  checkNumberValidity,
  checkPhoneNumberValidity,
  checkEmailValidity,
  checkNameValidity,
  checkPersonNameValidity,
  containsIndianNumerals,
  convertToArabicNumerals,
  checkPasswordComplexity,
  hashPassword,
} from "../commons/utilities";

import { countries_ar, countries_en } from "../commons/constants";
import { userSignUpRequestAPI } from "../api/api";
import { useAppearanceContext } from "../context/AppearanceContext";
import classes from "./page.module.css";
import signUpClasses from "./signUp.module.css";

function SignUp() {
  const { t, i18n } = useTranslation(["getStarted"]);
  const { isMobile, isSmallerScreen } = useAppearanceContext();

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  const [personName, setPersonName] = useState("");
  const [isInvalidPersonName, setIsInvalidPersonName] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validFullPhoneNumber, setValidFullPhoneNumber] = useState("");
  const [isInvalidPhoneNum, setIsInvalidPhoneNum] = useState(false);
  const [email, setEmail] = useState("");
  const [remail, setREmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidREmail, setIsInvalidREmail] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isInvalidCountry, setIsInvalidCountry] = useState(false);
  const [invalidCountryMessage, setInvalidCountryMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRPassword, setShowRPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [rpassword, setRPassword] = useState(null);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidRPassword, setIsInvalidRPassword] = useState(false);

  const [signUpRequestResponse, setSignUpRequestResponse] = useState(null);

  /*const [captachVerificationResponse, setCaptchaVerificationResponse] =
    useState(null);

  const recaptchaRef = useRef();

  useEffect(() => {
    if (captachVerificationResponse) {
      setIsBusy(false);
      if (captachVerificationResponse.success) {
        console.log("reCAPTCHA successfully verified.");
        submitSignUpRequest();
      } else {
        console.log("reCAPTCHA verification failed.");
        setModalAlertMessage(
          "reCAPTCHA verification failed. Please try again."
        );
        setShowModalAlert(true);
      }
      setCaptchaVerificationResponse(null);
    }
  }, [captachVerificationResponse]);
  

  useEffect(() => {
    if (getStartedResponse) {
      setIsBusy(false);
      if (getStartedResponse.success) {
        let message = `Thank you. Your request has been submitted successfully. We will contact you soon.`;
        setModalInfoMessage(message);
        setShowModalInfo(true);
      } else {
        let message =
          "Unfortunately, your request has not been submitted successfully. Please try again. ";
        if (getStartedResponse) {
          if (getStartedResponse.errorCode) {
            if (
              getStartedResponse.errorCode ===
              errorCodes.routes.missingProperties
            ) {
              message = message + `Submitted request had missing information.`;
            } else if (
              getStartedResponse.errorCode === errorCodes.routes.invalidInputs
            ) {
              message = message + `Submitted request had invalid information.`;
            } else if (
              getStartedResponse.errorCode === errorCodes.routes.corsNotAllows
            ) {
              message = message + `Originating IP is not allowed.`;
            } else {
              if (getStartedResponse.errorCode)
                message =
                  message + `Error code: ${getStartedResponse.errorCode}. `;
              if (getStartedResponse.errorMessage)
                message =
                  message +
                  `Error message: ${getStartedResponse.errorMessage}. `;
            }
          }
        }
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
      setGetStartedResponse(null);
    }
  }, [getStartedResponse]);
  */

  //check if sign up request response is received
  useEffect(() => {
    if (signUpRequestResponse) {
      console.log(`>>> signUpRequestResponse is received <<<<`);
      if (signUpRequestResponse.success) {
        console.log("signUpRequestResponse is valid.");
        let message = `Thank you ${signUpRequestResponse.username}. Your request has been submitted successfully. 
                        You will shortly receive an email on (${signUpRequestResponse.email}) for verification.`;
        setModalInfoMessage(message);
        setShowModalInfo(true);
      } else {
        console.log("Error in sign up request from backend >> do nothing");
        let message = `Unfortunately, your request has not been submitted successfully. Error: ${
          signUpRequestResponse.errorMessage || "undefined"
        }. Please try again. `;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
      setSignUpRequestResponse(null);
      setIsBusy(false);
    }
  }, [signUpRequestResponse]);

  const handlePersonName = (e) => {
    if (checkPersonNameValidity(e.target.value) || e.target.value === "") {
      setPersonName(e.target.value);
      setIsInvalidPersonName(false);
    } else {
      console.error("person name is invalid.");
      setIsInvalidPersonName(true);
    }
  };

  const handleAccountPhoneNumber = (e) => {
    console.log(
      `phone num: ${e.target.value}, country code: ${
        selectedCountry
          ? getCountryCallingCode(selectedCountry.value)
          : "undefined"
      }`
    );
    if (checkPhoneNumberValidity(e.target.value)) {
      setPhoneNumber(e.target.value);
      setIsInvalidPhoneNum(false);
    } else {
      console.error("phone num. is invalid.");
      setIsInvalidPhoneNum(true);
    }
  };

  const handleEmailInput = (e) => {
    if (checkEmailValidity(e.target.value)) {
      setEmail(e.target.value);
      setIsInvalidEmail(false);
    } else {
      setEmail(e.target.value);
      console.error("email is invalid.");
      setIsInvalidEmail(true);
    }
  };

  const handleREmailInput = (e) => {
    if (checkEmailValidity(e.target.value)) {
      setREmail(e.target.value);
      setIsInvalidREmail(false);
    } else {
      setREmail(e.target.value);
      console.error("email is invalid.");
      setIsInvalidREmail(true);
    }
  };

  const verifySignUpRequest = (e) => {
    e.preventDefault();

    if (!password || !rpassword) {
      setModalAlertMessage("Enter valid password.");
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }
    if (password !== rpassword) {
      setModalAlertMessage("Passwords do not match.");
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }

    if (!checkPasswordComplexity(password)) {
      setModalAlertMessage(
        "Password must contain at least 8 characters, including uppercase, lowercase, numbers and special characters."
      );
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }
    //////////////////////
    if(!email || !remail){
      setModalAlertMessage("Enter valid email.");
      setShowModalAlert(true);
      setIsInvalidEmail(true);
      setIsInvalidREmail(true);
      return;
    }

    if(email !== remail){
      setModalAlertMessage("Emails do not match.");
      setShowModalAlert(true);
      setIsInvalidEmail(true);
      setIsInvalidREmail(true);
      return;
    }

    if (!personName || !selectedCountry || !phoneNumber || !email) {
      if (!personName) setIsInvalidPersonName(true);
      if (!selectedCountry) {
        setIsInvalidCountry(true);
        setInvalidCountryMessage(t("helperMessageCountry"));
      }
      if (!phoneNumber) setIsInvalidPhoneNum(true);
      if (!email) setIsInvalidEmail(true);

      let message = `Please fill in all required fields.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
    } else if (isInvalidPersonName || isInvalidPhoneNum || isInvalidEmail) {
      let message = `Phone number, or email is invalid, plz check them.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    } else {
      //change phone number (if in hindu-arabic numerals to arabic numerals)
      let normalizedPhoneNumber;
      if (containsIndianNumerals(phoneNumber)) {
        console.log(`phone number contains indian numerals`);
        normalizedPhoneNumber = convertToArabicNumerals(phoneNumber);
        console.log(`converted phone number: ${normalizedPhoneNumber}`);
      } else {
        console.log(`phone number does not contain indian numerals`);
        normalizedPhoneNumber = phoneNumber;
      }

      let fullPhoneNumber = `+${getCountryCallingCode(
        selectedCountry.value
      )}${normalizedPhoneNumber}`;
      console.log(`full phone number: ${fullPhoneNumber}`);
      let parsedPhoneNumber = parsePhoneNumberFromString(
        fullPhoneNumber,
        selectedCountry.value
      );
      if (!parsedPhoneNumber?.isValid()) {
        let message = `Phone number is invalid.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
        return;
      }

      console.log(`valid phone number proceed...`);

      setValidFullPhoneNumber(fullPhoneNumber);

      //////////////////////

      //check if reCAPTCHA is verified
      /*const captchaValue = recaptchaRef.current.getValue();
      if (!captchaValue) {
        setModalAlertMessage("Please tick the checkbox of the reCAPTCHA!");
        setShowModalAlert(true);
        return;
      }

      console.log("submit reCAPTCHA to server for verification.");

      verifyCaptchaAPI(captchaValue, setCaptchaVerificationResponse);*/

      console.log(
        `All parameters are valid. Verify captacha and then submit sign up request...`
      );
      submitSignUpRequest(personName, selectedCountry.value, fullPhoneNumber, email);
    }
  };

  const submitSignUpRequest = (username, country, phoneNumber, email) => {
    const kilma = hashPassword(password);
    userSignUpRequestAPI(
      username,
      country,
      phoneNumber,
      email,
      kilma,
      setSignUpRequestResponse
    );

    setIsBusy(true);
    setPersonName("");
    setSelectedCountry(null);
    setPhoneNumber("");
    setEmail("");
    setREmail("");
    setPassword("");
    setRPassword("");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={signUpClasses.getStartedContainer}>
        <p className={classes.titleText}>Sign Up</p>
          <form
            className={
              isSmallerScreen
                ? signUpClasses.getStartedForm_mobile
                : signUpClasses.getStartedForm
            }
            onSubmit={verifySignUpRequest}
          >
            <div className={signUpClasses.getStartedElement}>
              <TextField
                type="text"
                id="personName"
                label={t("yourName")}
                className={signUpClasses.input_element}
                autoComplete="off"
                onChange={handlePersonName}
                value={personName}
                error={isInvalidPersonName}
                helperText={isInvalidPersonName ? t("helperMessageName") : null}
                required
              />
            </div>

            <div className={signUpClasses.getStartedElement}>
              <MyAutoComplete
                id="country"
                list={i18n.language === "ar" ? countries_ar : countries_en}
                title={t("country")}
                setSelectedOption={(value) => {
                  setSelectedCountry(value);
                  setIsInvalidCountry(false);
                }}
                errorState={isInvalidCountry}
                errorMessage={invalidCountryMessage}
                isNull={!selectedCountry}
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                type="text"
                id="phoneNumber"
                label={t("phoneNumber")}
                className={signUpClasses.input_element}
                autoComplete="off"
                onChange={handleAccountPhoneNumber}
                value={phoneNumber}
                error={isInvalidPhoneNum}
                helperText={
                  isInvalidPhoneNum ? t("helperMessagePhoneNumber") : null
                }
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                type="email"
                id="email"
                label={t("email")}
                className={signUpClasses.input_element}
                autoComplete="off"
                helperText={isInvalidEmail ? t("helperMessageEmail") : null}
                onChange={handleEmailInput}
                value={email}
                error={isInvalidEmail}
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                type="email"
                id="email"
                label={t("renter email")}
                className={signUpClasses.input_element}
                autoComplete="off"
                helperText={isInvalidREmail ? t("helperMessageEmail") : null}
                onChange={handleREmailInput}
                value={remail}
                error={isInvalidREmail}
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                className="input_element"
                id="password"
                label={t("password")}
                rows={1}
                type={showPassword ? "text" : "password"}
                value={password ? password : ""}
                error={isInvalidPassword}
                onChange={(e) => {
                  setIsInvalidPassword(false);
                  setPassword(e.target.value);
                  console.log(`password: ${e.target.value}`);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setIsInvalidPassword(false);
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                className="input_element"
                id="r_password"
                label={t("renter password")}
                rows={1}
                type={showRPassword ? "text" : "password"}
                value={rpassword ? rpassword : ""}
                error={isInvalidRPassword}
                onChange={(e) => {
                  setIsInvalidRPassword(false);
                  setRPassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div onClick={() => setShowRPassword(!showRPassword)}>
                        {showRPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
          </form>
          {/*<div className={classes.button_div}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            />
          </div>*/}
          <div className={signUpClasses.button_div}>
            <MyButton
              variant={"primary"}
              onClickHandler={verifySignUpRequest}
              title={t("submit")}
            />
          </div>
        </div>

        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => {
            setShowModalAlert(false);
          }}
        />

        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => {
            setShowModalInfo(false);
          }}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default SignUp;
