
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function MyAutoComplete({
  list,
  initialLabel,
  title,
  setSelectedOption,
  errorState,
  errorMessage,
  isNull,
}) {
  const [selectedOption_local, setSelectedOption_local] = useState(null);

  useEffect(() => {
    if (list && initialLabel) {
      const itemWithLabel = list.find((item) => item.label === initialLabel);
      if (itemWithLabel) {
        setSelectedOption_local(itemWithLabel);
        setSelectedOption(itemWithLabel);
      }
    }
  }, [list, initialLabel, setSelectedOption]);

  useEffect(() => {
    if (isNull) {
      setSelectedOption_local(null);
      setSelectedOption(null);
    }
  }, [isNull]);

  const handleChange = (event, newValue) => {
    setSelectedOption_local(newValue);
    setSelectedOption(newValue);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Autocomplete
        id="combo-box-demo"
        options={list}
        getOptionLabel={(option) => option.label}
        value={selectedOption_local}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            error={errorState ? errorState : false}
            helperText={
              errorState ? (errorMessage ? errorMessage : null) : null
            }
          />
        )}
      />
    </Box>
  );
}

export default MyAutoComplete;
