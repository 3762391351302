import React, { useState, useEffect, createContext, useContext } from "react";

// Create empty context
const AppearanceContext = createContext({});
const MOBILE_VIEWPORT_WIDTH = 768;
const SMALLER_SCREEN_WIDTH = 970;

export function useAppearanceContext() {
  return useContext(AppearanceContext);
}

export function AppearanceContextProvider({ children }) {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_VIEWPORT_WIDTH
  );
  const [isSmallerScreen, setIsSmallerScreen] = useState(
    window.innerWidth <= SMALLER_SCREEN_WIDTH
  );

  //const [selectedMenuTab, setSelectedMenuTab] = useState(null);

  // Update isMobile state whenever the window size changes
  useEffect(() => {
    const handleResize = () => {
      //console.log(`handleResize: window.innerWidth: ${window.innerWidth}.`);
      setIsMobile(window.innerWidth <= MOBILE_VIEWPORT_WIDTH);
      setIsSmallerScreen(window.innerWidth <= SMALLER_SCREEN_WIDTH);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  

  return (
    <AppearanceContext.Provider
      value={{
        isMobile,
        isSmallerScreen,
        //selectedMenuTab,
        //setSelectedMenuTab,
      }}
    >
      {children}
    </AppearanceContext.Provider>
  );
}