import CryptoJS from "crypto-js";
//import { getCountryCallingCode } from "libphonenumber-js";
//import { getCodeList } from "country-list";
const dayjs = require("dayjs");

export const hashPassword = (password) => {
  const hash = CryptoJS.SHA256(password);
  return hash.toString(CryptoJS.enc.Hex);
};

export const checkPasswordComplexity = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecial = /[!@#$%^&*]/.test(password);

  console.log(
    `password: ${password}, length: ${password.length}, minLength: ${minLength}, 
    hasUpperCase: ${hasUpperCase}, hasLowerCase: ${hasLowerCase}, 
    hasNumbers: ${hasNumbers}, hasSpecial: ${hasSpecial}`
  );

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumbers &&
    hasSpecial
  );
};

export const checkNumberValidity = (text) => {
  // Remove leading/trailing spaces and check if it's a valid number
  const trimmedText = text.trim();
  return !isNaN(trimmedText);
};

/*export const checkPhoneNumberValidity = (text) => {
  // Remove leading/trailing spaces
  const trimmedText = text.trim();

  // Regular expression to match Arabic and Indian numerals
  const regex = /^[0-9\u0660-\u0669\u06F0-\u06F9]*$/;

  // Check if it's a valid number in English, Arabic numerals, or Indian numerals
  return regex.test(trimmedText);
};*/
export const checkPhoneNumberValidity = (text) => {
  // Remove leading/trailing spaces
  const trimmedText = text.trim();

  // Check the first character
  const firstChar = trimmedText.charAt(0);

  // Regular expressions to match English, Arabic and Indian numerals
  const englishRegex = /^[0-9]*$/;
  const arabicRegex = /^[\u0660-\u0669]*$/;
  const indianRegex = /^[\u06F0-\u06F9]*$/;

  // Check if it's a valid number in English, Arabic numerals, or Indian numerals
  if (englishRegex.test(firstChar)) {
    return englishRegex.test(trimmedText);
  } else if (arabicRegex.test(firstChar)) {
    return arabicRegex.test(trimmedText);
  } else if (indianRegex.test(firstChar)) {
    return indianRegex.test(trimmedText);
  } else {
    return false;
  }
};

export const checkPositiveNumberValidity = (text) => {
  // Remove leading/trailing spaces and check if it's a valid number
  const trimmedText = text.trim();
  return !(isNaN(trimmedText) || trimmedText < 0);
};

export const checkIntegerNumberValidity = (text) => {
  // Use a regular expression to check for a positive integer
  const positiveIntegerPattern = /^[1-9]\d*$/;
  return positiveIntegerPattern.test(text.trim());
};

export function checkArNameValidity(text) {
  var regex = /^[^a-zA-Z]+$/;
  //var regex = /^[^a-zA-Z\p{Arabic}0-9\s]+$/;
  return regex.test(text);
}

export function checkEnNameValidity(text) {
  var regex = /^[A-Za-z][A-Za-z0-9\s\-.,&'()]*$/;
  return regex.test(text);
}

/*export const checkNameValidity = (text) => {
  var regex = /^[A-Za-z][A-Za-z0-9\s\-.,&'()]*$/;
  const arabicRegex = /^[\u0600-\u06FF\s]+$/;

  //check to see if the entered text is valid english or arabic text.
  return regex.test(text) || arabicRegex.test(text);
};*/
export const checkNameValidity = (text) => {
  if (text.length > 12) {
    return false;
  }
  var regex = /^[A-Za-z0-9\s]*$/;
  const arabicRegex = /^[\u0600-\u06FF\s]+$/;

  //check to see if the entered text is valid english or arabic text.
  return regex.test(text) || arabicRegex.test(text);
};

export const checkPersonNameValidity = (text) => {
  if (text.length > 12) {
    return false;
  }
  var regex = /^[A-Za-z\s]*$/;
  //const arabicRegex = /^[\u0600-\u06FF\s]+$/;
  const arabicRegex = /^[\u0621-\u063A\u0641-\u064A\s]+$/;


  //check to see if the entered text is valid English or Arabic text.
  return regex.test(text) || arabicRegex.test(text);
};

export const checkEmailValidity = (email) => {
  if (email.length > 50) {
    return false;
  }
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailPattern.test(email);
}


export const checkMessageValidity = (text) => {
  if (text.length > 200) {
    return false;
  }
  
  var regex = /^[A-Za-z0-9.\s\n]*$/;
  const arabicRegex = /^[\u0621-\u063A\u0641-\u064A0-9.\s\n]+$/;

  console.log(`text: ${text}, and its length: ${text.length}, and its validity: ${regex.test(text) || arabicRegex.test(text)}`);

  //check to see if the entered text is valid English or Arabic text.
  return regex.test(text) || arabicRegex.test(text);
};


export const checkGoogleMapUrlValidity = (url) => {
  const googleMapUrlPattern = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;
  return googleMapUrlPattern.test(url);
};

/*export const checkInstagramUrlValidity = (url) => {
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_]+\/?$/;
  return instagramUrlPattern.test(url);
};*/

/*
export const checkInstagramUrlValidity = (url) => {
  //console.log(`This is checkInstagramUrlValidity, url is ${url}`);
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_]+(\/\?[\w=&]*)?$/;
  //const valid = instagramUrlPattern.test(url);
  //console.log(`valid: ${valid}`);
  return instagramUrlPattern.test(url);
};
*/

export const checkInstagramUrlValidity = (url) => {
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_.]+(\/\?[\w=&]*)?$/;
  return instagramUrlPattern.test(url);
};

export const extractTimeFromDateObject = (dayjsObject) => {
  const result = { valid: false, hours: 0, minutes: 0 };

  if (!dayjsObject.isValid()) {
    console.log("Invalid Day.js object");
    return result;
  }

  console.log("dayjsObject is a valid dayjs object");

  // Extract time components
  const hours = dayjsObject.hour();
  const minutes = dayjsObject.minute();

  console.log(`hours: ${hours}, minutes: ${minutes}`);

  // Check if the time components are valid
  if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
    result.valid = true;
    result.hours = hours;
    result.minutes = minutes;
    return result;
  } else {
    result.valid = false;
    return result;
  }
};

export function convertToIndianNumerals(number) {
  const arabicToIndian = {
    0: "\u0660",
    1: "\u0661",
    2: "\u0662",
    3: "\u0663",
    4: "\u0664",
    5: "\u0665",
    6: "\u0666",
    7: "\u0667",
    8: "\u0668",
    9: "\u0669",
  };

  return number
    .toString()
    .split("")
    .map((char) => arabicToIndian[char] || char)
    .join("");
}

export const convertToArabicNumerals = (number) => {
  const indianToArabic = {
    "\u0660": "0",
    "\u0661": "1",
    "\u0662": "2",
    "\u0663": "3",
    "\u0664": "4",
    "\u0665": "5",
    "\u0666": "6",
    "\u0667": "7",
    "\u0668": "8",
    "\u0669": "9",
  };

  return number.split('').map((char) => indianToArabic[char] || char)
    .join("");
}

export const containsIndianNumerals = (number) => {
  const indianNumerals = [
    "\u0660",
    "\u0661",
    "\u0662",
    "\u0663",
    "\u0664",
    "\u0665",
    "\u0666",
    "\u0667",
    "\u0668",
    "\u0669",
  ];
  return number.split("").some((char) => indianNumerals.includes(char));
}

/*
export const getCountryCode = (country) => {
  try {
    let cc = getCountryCallingCode(country);
    return cc ? cc : undefined;
  } catch (error) {
    console.error(`Could not get calling code for country: ${country}`);
    return undefined;
  }
};*/

export function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  for (let i = 0; i < length; i++) {
    result += characters.charAt(array[i] % charactersLength);
  }
  return result;
}