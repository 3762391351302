import image1 from "./swiperImages/DALLE2.webp";
import image2 from "./swiperImages/DALLE3.webp";
import image3 from "./swiperImages/DALLE1.webp";
import image4 from "./swiperImages/DALLE4.webp";
import image5 from "./swiperImages/DALLE5.webp";

export const images = [
  {
    title: "Title 1",
    title_ar: "أفضل بكثير",
    text: "Random text example one in English.",
    text_ar: "تصفح المنيو بشكل أسهل وممتع. المنيو الإلكترونية أكثر نظافة بكثير من المنيو الورقية.",
    index: 0,
    image: image1,
  },
  {
    title: "Title 2",
    title_ar: "على هواتفهم",
    text: "Here is another random example text.",
    text_ar: "بدلاً من وجود شاشة كبيرة وطوابير طويلة، اجعل المنيو سهلة الوصول من قبل عملائك على أجهزتهم الذكية.",
    index: 1,
    image: image3,
  },
  {
    title: "Title 3",
    title_ar: "من المنيو إلى المطبخ",
    text: "This is a different random English text.",
    text_ar: "ترسل الطلبات في ثوانٍ إلى المطبخ. لا مزيد من الانتظار للنادل ليأتي ويأخذ طلبك.",
    index: 2,
    image: image2,
  },
  {
    title: "Title 4",
    title_ar: "سهل الإدارة",
    text: "Sample random text for testing purposes.",
    text_ar: "غير عناصر المنيو والأسعار والأوصاف في ثوان. لا حاجة لطباعة منيو جديدة.",
    index: 3,
    image: image4,
  },
  {
    title: "Title 5",
    title_ar: "واكب التقنية",
    text: "Another random sentence as placeholder text.",
    text_ar: "المنيو الورقية قديمة. حافظ على مواكبة أحدث التكنولوجيا.",
    index: 4,
    image: image5,
  }
];
