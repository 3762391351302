import { TOKEN_NAME } from "../commons/constants";
import { generateRandomString } from "../commons/utilities";

const createRoomURL = "api/createRoom";
const endMeetingURL = "api/endMeeting";
const protectedRouteURL = "api/protected";
const oauthAuthorize = "api/oauth/authorize";
// -- these are not used anymore (should be called by Moodle as part of OAuth2 flow)
const oauthToken = "api/oauth/token";
const userInfoRouteURL = "api/userinfo";
//----------------------------------------
const testRouteURL = "api/test";
const loginURL = "api/login";
const usersURL = "api/users";
const verificationURL = "api/verification";
const forgotPasswordURL = "api/forgotPassword";
const resetPasswordURL = "api/resetPassword";
const lmsCoursesURL = "api/lmsCourses";
const registerationURL = "api/registeration";
const regRequestsURL = "api/regRequests";
const enrollmentAcceptURL = "api/enrollmentAccept";
const enrollmentRejectURL = "api/enrollmentReject";
const userCoursesURL = "api/userCourses";
const JWTStatusURL = "api/jwtStatus";
const logoutURL = "api/logout";

/**************************************************************************************
 * ****
 * ****
 * **** Private Methods
 * ****
 * ****
 * ***********************************************************************************/

/*
const login_local = async (
  username,
  clientID,
  redirectURI,
  state,
  password
) => {
  const params = new URLSearchParams({
    response_type: "code", // Required by OAuth2
    client_id: clientID, // Replace with the actual client ID
    redirect_uri: redirectURI, // Set this to your frontend's callback route
    state: state,
    username: username,
    password: password,
  });

  try {
    const res = await fetch(`${oauthAuthorize}?${params.toString()}`, {
      method: "GET",
      credentials: "include", // Allows cookies to be sent for session management
    });

    console.log(`res.status: ${res.status}.`);

    // Check for failed authorization (non-200 status)
    if (!res.ok) {
      const errorText = await res.text(); // or res.json() if server sends JSON error
      console.error(`Authorization failed: ${errorText}`);
      return { error: "Authorization failed", details: errorText };
    }

    // Successful authorization - handle the redirect
    //const redirectURL = res.url;
    //console.log(`Redirecting to: ${redirectURL}`);
    //window.location.href = redirectURL;

    //return { message: "Authorization successful" };
    const data = await res.json();
    console.log(`Authorization data: ${JSON.stringify(data)}.`);
    return data;
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    return null;
  }
};


const getToken_local = async (
  username,
  clientID,
  clientSecret,
  redirectURI,
  authorizationCode
) => {
  //const data = {
  //  authorizationCode: authorizationCode,
  //};
  try {
    const res = await fetch(oauthToken, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        code: authorizationCode,
        grant_type: "authorization_code",
        username: username,
        redirect_uri: redirectURI,
        client_id: clientID,
        client_secret: clientSecret,
      }),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`getToken data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not get token - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from getToken: ${error}`);
    return null;
  }
};

const getUserInfo_local = async () => {
  try {
    const res = await fetch(userInfoRouteURL, {
      method: "GET",
      headers: {
        //Authorization: `Bearer ${localStorage.getItem(
        //  TOKEN_NAME
        //)}`,
        "Content-Type": "application/json",
      },
      credentials: "include", // This is crucial for sending cookies with the request
    });

    console.log(`res.status: ${res.status}.`);

    if (res) {
      const data = await res.json();
      console.log(`getUserInfo data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not get user info - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${userInfoRouteURL}: ${error}`);
    return null;
  }
};
*/
/*
const getLMSLink_local = async (username) => {
  const clientID = process.env.REACT_APP_CLIENT_ID;
  console.log(`clientID: ${clientID}.`);
  const moodleRedirectUri =
    "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";
  console.log(
    "Trying to navigate (full page) to backend's authorize endpoint and then - hopefully - redirect URI to Moodle's."
  );
  const wantsurl = "https://lms1.ediscool.com/moodle/";
  const randomSessionKey = generateRandomString(10);
  console.log(`randomSessionKey: ${randomSessionKey}`);
  const state = encodeURIComponent(
    `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(
      wantsurl
    )}&sesskey=${randomSessionKey}&id=1&scope=openid profile email&username=${username}`
  );

  const params = new URLSearchParams({
    response_type: "code",
    client_id: clientID,
    redirect_uri: moodleRedirectUri,
    state: state,
  });

  // Trigger full-page navigation to the backend's /authorize endpoint
  const url = `${oauthAuthorize}?${params.toString()}`;

  //console.log(`Redirecting user to: ${url}`);
  //window.location.href = url;

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`getLMSLink data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not get LMS link - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    return null;
  }
};
*/
/*
const accessProtectedRoute_local = async () => {
  console.log(
    `This is accessProtectedRoute_local, and TOKEN_NAME: ${TOKEN_NAME}.`
  );
  try {
    const res = await fetch(protectedRouteURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // This is crucial for sending cookies with the request
    });

    console.log(`res.status: ${res.status}.`);

    if (res) {
      const data = await res.json();
      console.log(`accessProtectedRoute data: ${JSON.stringify(data)}.`);
      if (res.status === 200) {
        console.log("Access to protected route successful.");
        return {
          success: true,
          message: "Access to protected route successful.",
          data: data,
        };
      } else {
        console.error("Access to protected route failed.");
        return {
          success: false,
          message: "Access to protected route failed.",
          data: data,
        };
      }
    }
    console.error("Could not access protected route - return null");
    return null;
  } catch (error) {
    console.error(`Error fetching from ${protectedRouteURL}: ${error}`);
    return null;
  }
};
*/

/*
const login_local = async (username, password) => {
  const params = new URLSearchParams({
    response_type: 'code', // Required by OAuth2
    client_id: 1, // Replace with the actual client ID
    redirect_uri: 'http://localhost:3001/callback', // Set this to your frontend's callback route
    username: username,
    password: password,
  });

  try {
    const res = await fetch(`${oauthAuthorize}?${params.toString()}`, {
      method: "GET",
      credentials: "include", // Allows cookies to be sent for session management
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`Authorization data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("Could not authorize - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    return null;
  }
};
*/

const createRoom_local = async (
  meetingID,
  meetingName,
  moderatorPW,
  attendeePW
) => {
  const data = {
    meetingID: meetingID,
    meetingName: meetingName,
    moderatorPW: moderatorPW,
    attendeePW: attendeePW,
  };
  try {
    const res = await fetch(createRoomURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`createRoom data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("could not create room - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${createRoomURL}: ${error}`);
    return null;
  }
};

const endMeeting_local = async (meetingID, moderatorPW) => {
  const data = {
    meetingID: meetingID,
    moderatorPW: moderatorPW,
  };
  try {
    const res = await fetch(endMeetingURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      console.log(`endMeeting data: ${JSON.stringify(data)}.`);
      return data;
    } else {
      console.error("could not end meeting - return null");
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${endMeetingURL}: ${error}`);
    return null;
  }
};

/**
 * This method fetches from given URL and redirects to /login on 401 Unauthorized
 * @param {*} url 
 * @param {*} options 
 * @returns 
 */
const fetchWithRedirect = async (url, options = {}) => {
  try {
    const res = await fetch(url, {
      ...options,
      credentials: "include", // Always include cookies
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
    });

    // Handle 401 Unauthorized
    if (res.status === 400 || res.status === 401 || res.status === 403) {
      console.error(
        `res.status: ${res.status}, unauthorized access to ${url}. Redirecting to /login.`
      );
      if (window.location.pathname !== "/login") {
        window.location.href = "/login"; // Redirect to the login page
      }
      return null; // Ensure subsequent code does not execute
    }

    // Return the response or parsed JSON data
    if (res.ok) {
      return await res.json();
    } else {
      console.error(`Request to ${url} failed with status ${res.status}.`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${url}: ${error}`);
    return null;
  }
};


/**
 * This method fetches from given URL without redirecting to /login on 401 Unauthorized
 * @param {*} url
 * @param {*} options
 * @returns
 * */
const fetchWithoutRedirect = async (url, options = {}) => {
  try {
    const res = await fetch(url, {
      ...options,
      //credentials: "include", // Always include cookies
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
    });

    // Handle 401 Unauthorized
    if (res.status === 400 || res.status === 401 || res.status === 403) {
      console.error(
        `res.status: ${res.status}, unauthorized access to ${url}. Do NOT redirect to /login.`
      );
      return null; // Ensure subsequent code does not execute
    }

    // Return the response or parsed JSON data
    if (res.ok) {
      return await res.json();
    } else {
      console.error(`Request to ${url} failed with status ${res.status}.`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching from ${url}: ${error}`);
    return null;
  }
};

/**************************************************************************************
 * ****
 * ****
 * **** Public Methods
 * ****
 * ****
 * ***********************************************************************************/

/**
 * This method communicates with API to login user
 * @param {*} username
 * @param {*} kilma
 * @param {*} setLoginResponse
 * */
export const userLoginAPI = async (username, kilma, setLoginResponse) => {
  let result;

  console.log(
    `This is userLoginAPI username: ${username}, password: ${kilma}.`
  );

  try {
    const data = await fetchWithRedirect(loginURL, {
      method: "POST",
      body: JSON.stringify({ username: username, kilma: kilma }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("login response is undefined.");
      result = {
        success: false,
        errorMessage: "login response is undefined.",
      };
      setLoginResponse(result);
    } else {
      setLoginResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${loginURL}: ${error}`);
    setLoginResponse({
      success: false,
      errorMessage: "Error fetching from login.",
    });
  }
};

/**
 * This method communicates with API to sign-up new user
 * @param {*} username 
 * @param {*} country 
 * @param {*} phoneNumber 
 * @param {*} email 
 * @param {*} setSignUpRequestResponse 
 */
export const userSignUpRequestAPI = async (
  username,
  country,
  phoneNumber,
  email,
  kilma,
  setSignUpRequestResponse
) => {
  let result;

  console.log(
    `This is userSignUpRequestAPI username: ${username}, country: ${country}, phoneNumber: ${phoneNumber}, email: ${email}.`
  );

  try {
    const data = await fetchWithoutRedirect(usersURL, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        country: country,
        phoneNumber: phoneNumber,
        email: email,
        kilma: kilma,
      }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("sign-up request response is undefined.");
      result = {
        success: false,
        errorMessage: "sign-up request response is undefined.",
      };
      setSignUpRequestResponse(result);
    } else {
      setSignUpRequestResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${usersURL}: ${error}`);
    setSignUpRequestResponse({
      success: false,
      errorMessage: "Error fetching from signUpRequest.",
    });
  }
};


export const verifyEmailAndPhoneAPI = async (
  username,
  email,
  phoneNumber,
  setVerifyEmailAndPhoneResponse
) => {
  let result;

  console.log(
    `This is verifyEmailAndPhoneAPI username: ${username}, email: ${email}, phoneNumber: ${phoneNumber}.`
  );

  try {
    const data = await fetchWithoutRedirect(verificationURL, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        email: email,
        phoneNumber: phoneNumber,
      }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("verifyEmailAndPhone response is undefined.");
      result = {
        success: false,
        errorMessage: "verifyEmailAndPhone response is undefined.",
      };
      setVerifyEmailAndPhoneResponse(result);
    } else {
      setVerifyEmailAndPhoneResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${verificationURL}: ${error}`);
    setVerifyEmailAndPhoneResponse({
      success: false,
      errorMessage: "Error fetching from verifyEmailAndPhone.",
    });
  }

}

/**
 * This method communicates with API to reset password (reset-password email is sent to user)
 * @param {*} email
 * @param {*} setForgotPasswordResponse
 * */
export const forgotPasswordAPI = async (email, setForgotPasswordResponse) => {
  let result;

  console.log(`This is forgotPasswordAPI email: ${email}.`);

  try {
    const data = await fetchWithoutRedirect(forgotPasswordURL, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("forgotPassword response is undefined.");
      result = {
        success: false,
        errorMessage: "forgotPassword response is undefined.",
      };
      setForgotPasswordResponse(result);
    } else {
      setForgotPasswordResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${forgotPasswordURL}: ${error}`);
    setForgotPasswordResponse({
      success: false,
      errorMessage: "Error fetching from forgotPassword.",
    });
  }
}

/**
 * This method communicates with API to reset password (new password is set)
 * @param {*} email
 * @param {*} token
 * @param {*} ukilma
 * @param {*} kilma
 * @param {*} setResetPasswordResponse
 * */
export const resetPasswordAPI = (
  email,
  token,
  ukilma,
  kilma,
  setResetPasswordResponse
) => {
  let result;

  console.log(
    `This is resetPasswordAPI email: ${email}, token: ${token}, ukilma: ${ukilma}, kilma: ${kilma}.`
  );

  fetchWithoutRedirect(resetPasswordURL, {
    method: "POST",
    body: JSON.stringify({
      email: email,
      token: token,
      ukilma: ukilma,
      kilma: kilma,
    }),
  })
    .then((data) => {
      if (data === null || typeof data === "undefined") {
        console.error("resetPassword response is undefined.");
        result = {
          success: false,
          errorMessage: "Reset-password response is undefined",
        };
        setResetPasswordResponse(result);
      } else {
        setResetPasswordResponse(data);
      }
    })
    .catch((error) => {
      console.error(`Error posting to ${resetPasswordURL}: ${error}`);
      setResetPasswordResponse({
        success: false,
        errorMessage: "Unable to send reset-password request",
      });
    });
}


/**
 * This method fetches (all) courses from LMS (moodle)
 * @param {*} setLMSCoursesResponse
 */
export const fetchLMSCoursesAPI = async (setLMSCoursesResponse) => {
  console.log(`This is fetchLMSCoursesAPI.`);
  try {
    const data = await fetchWithoutRedirect(lmsCoursesURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("fetchLMSCourses response is undefined.");
      setLMSCoursesResponse({
        success: false,
        errorMessage: "fetchLMSCourses response is undefined.",
      });
    } else {
      setLMSCoursesResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${lmsCoursesURL}: ${error}`);
    setLMSCoursesResponse({
      success: false,
      errorMessage: "Error fetching from fetchLMSCourses.",
    });
  }
};

/**
 * This method fetches courses in which this user (identified by jwt) is currently enrolled
 * @param {*} setGetUserCoursesResponse
 */
export const fetchUserCoursesAPI = async (setGetUserCoursesResponse) => {
  console.log(`This is fetchUserCoursesAPI.`);
  try {
    const data = await fetchWithoutRedirect(userCoursesURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("fetchUserCourses response is undefined.");
      setGetUserCoursesResponse({
        success: false,
        errorMessage: "fetchUserCourses response is undefined.",
      });
    } else {
      setGetUserCoursesResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${userCoursesURL}: ${error}`);
    setGetUserCoursesResponse({
      success: false,
      errorMessage: "Error fetching from fetchLMSCourses.",
    });
  }
};

export const fetchRegisterationRequestsAPI = async (
  setGetRegRequestsResponse
) => {
  console.log(`This is fetchRegisterationRequestsAPI.`);
  try {
    const data = await fetchWithRedirect(regRequestsURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("fetchRegisterationRequests response is undefined.");
      setGetRegRequestsResponse({
        success: false,
        errorMessage: "fetchRegisterationRequests response is undefined.",
      });
    } else {
      setGetRegRequestsResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${regRequestsURL}: ${error}`);
    setGetRegRequestsResponse({
      success: false,
      errorMessage: "Error fetching registeration requests.",
    });
  }
};

export const enrollInCourseRequestAPI = async (
  courseId,
  courseShortName,
  setEnrollInCourseResponse
) => {
  let result;

  console.log(
    `This is enrollInCourseRequestAPI courseId: ${courseId}, courseShortName: ${courseShortName}.`
  );

  try {
    const data = await fetchWithRedirect(registerationURL, {
      method: "POST",
      body: JSON.stringify({
        courseId: courseId,
        courseShortName: courseShortName,
      }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("enrollInCourse response is undefined.");
      result = {
        success: false,
        errorMessage: "enrollInCourse response is undefined.",
      };
      setEnrollInCourseResponse(result);
    } else {
      setEnrollInCourseResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${registerationURL}: ${error}`);
    setEnrollInCourseResponse({
      success: false,
      errorMessage: "Error fetching from enrollInCourse.",
    });
  }
};

/**
 * This method communicates with API to accept registeration/enrollment request
 * @param {*} regRequestId
 * @param {*} userId
 * @param {*} courseId
 * @param {*} setAcceptEnrollmentResponse
 */
export const acceptEnrollmentRequestAPI = async (
  regRequestId,
  userId,
  courseId,
  setAcceptEnrollmentResponse
) => {
  let result;

  console.log(
    `This is acceptEnrollmentRequestAPI regRequestId: ${regRequestId}, userId: ${userId}, courseId: ${courseId}.`
  );

  try {
    const data = await fetchWithRedirect(enrollmentAcceptURL, {
      method: "POST",
      body: JSON.stringify({
        regRequestId: regRequestId,
        userId: userId,
        courseId: courseId,
      }),
    });

    //console.log(`acceptEnrollment data: ${JSON.stringify(data)}.`);

    if (data === null || typeof data === "undefined") {
      console.error("acceptEnrollment response is undefined.");
      result = {
        success: false,
        errorMessage: "acceptEnrollment response is undefined.",
      };
      setAcceptEnrollmentResponse(result);
    } else {
      setAcceptEnrollmentResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${enrollmentAcceptURL}: ${error}`);
    setAcceptEnrollmentResponse({
      success: false,
      errorMessage: "Error fetching from acceptEnrollment.",
    });
  }
};

/**
 * This method communicates with API to reject registeration/enrollment request
 * @param {*} regRequestId
 * @param {*} userId
 * @param {*} courseId
 * @param {*} setRejectEnrollmentResponse
 */
export const rejectEnrollmentRequestAPI = async (
  regRequestId,
  userId,
  courseId,
  note,
  setRejectEnrollmentResponse
) => {
  let result;

  console.log(
    `This is rejectEnrollmentRequestAPI regRequestId: ${regRequestId}, userId: ${userId}, courseId: ${courseId}, note: ${note}.`
  );

  try {
    const data = await fetchWithRedirect(enrollmentRejectURL, {
      method: "POST",
      body: JSON.stringify({
        regRequestId: regRequestId,
        userId: userId,
        courseId: courseId,
        note: note,
      }),
    });

    if (data === null || typeof data === "undefined") {
      console.error("rejectEnrollment response is undefined.");
      result = {
        success: false,
        errorMessage: "rejectEnrollment response is undefined.",
      };
      setRejectEnrollmentResponse(result);
    } else {
      setRejectEnrollmentResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${enrollmentAcceptURL}: ${error}`);
    setRejectEnrollmentResponse({
      success: false,
      errorMessage: "Error fetching from rejectEnrollment.",
    });
  }
};

export const fetchJWTStatusAPI = async (setJWTStatusResponse) => {
  console.log(`This is fetchJWTStatusAPI.`);

  try {
    const data = await fetchWithoutRedirect(JWTStatusURL, {
      method: "GET",
    });

    if (data && data.success) {
      setJWTStatusResponse({
        success: true,
        authenticated: data.authenticated || false,
        username: data.username || null,
        email: data.email || null,
        userId: data.userId || null,
        role: data.role || null,
        errorMessage: data.errorMessage || null,
      });
    } else {
      console.error("fetchJWTStatus response is undefined, or failed.");
      setJWTStatusResponse({
        success: true,
        authenticated: false,
        username: null,
        email: null,
        userId: null,
        role: null,
        errorMessage: data && data.errorMessage ? data.errorMessage : null,
      });
    }
  } catch (error) {
    console.error(`Error fetching from ${JWTStatusURL}: ${error}`);
    setJWTStatusResponse({
      success: true,
      authenticated: false,
      username: null,
      email: null,
      userId: null,
      role: null,
      errorMessage: "Error fetching from fetchJWTStatus.",
    });
  }
};

export const logoutAPI = async (setLogoutResponse) => {
  console.log(`This is logoutAPI.`);
  let result;

  /*logout_local().then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("logout response is undefinied.");
      result.success = false;
      result.errorMessage = "logout response is undefinied.";
    } else {
      console.log(`logout response: ${JSON.stringify(data)}.`);
      result = data;
    }
    setLogoutResponse(result);
  });
  */

  try {
    const data = await fetchWithRedirect(logoutURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("logout response is undefined.");
      setLogoutResponse({
        success: false,
        errorMessage: "logout response is undefined.",
      });
    } else {
      console.log(`logout response: ${JSON.stringify(data)}.`);
      setLogoutResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${logoutURL}: ${error}`);
    setLogoutResponse({
      success: false,
      errorMessage: "Error fetching from logout.",
    });
  }
};


export const getLMSLink_API = async (username, setGetLMSLinkResponse) => {
  const clientID = process.env.REACT_APP_CLIENT_ID;
  console.log(`clientID: ${clientID}.`);
  const moodleRedirectUri =
    "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";
  console.log(
    "Trying to navigate (full page) to backend's authorize endpoint and then - hopefully - redirect URI to Moodle's."
  );
  const wantsurl = "https://lms1.ediscool.com/moodle/";
  const randomSessionKey = generateRandomString(10);
  console.log(`randomSessionKey: ${randomSessionKey}`);
  const state = encodeURIComponent(
    `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(
      wantsurl
    )}&sesskey=${randomSessionKey}&id=1&scope=openid profile email&username=${username}`
  );

  const params = new URLSearchParams({
    response_type: "code",
    client_id: clientID,
    redirect_uri: moodleRedirectUri,
    state: state,
  });

  // Trigger full-page navigation to the backend's /authorize endpoint
  const url = `${oauthAuthorize}?${params.toString()}`;

  let result;
  /*getLMSLink_local(username).then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("getLMSLink response is undefinied.");
      result.success = false;
      result.errorMessage = "getLMSLink response is undefinied.";
    } else {
      result = data;
    }
    setGetLMSLinkResponse(result);
  });*/
  try {
    const data = await fetchWithRedirect(url, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("getLMSLink response is undefined.");
      result = {
        success: false,
        errorMessage: "getLMSLink response is undefined.",
      };
      setGetLMSLinkResponse(result);
    } else {
      setGetLMSLinkResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${oauthAuthorize}: ${error}`);
    setGetLMSLinkResponse({
      success: false,
      errorMessage: "Error fetching from getLMSLink.",
    });
  }
};

export const accessProtectedRoute_API = async (
  setGetProtectedRouteResponse
) => {
  let result;
  try {
    const data = await fetchWithRedirect(protectedRouteURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("accessProtectedRoute response is undefined.");
      result = {
        success: false,
        errorMessage: "accessProtectedRoute response is undefined.",
      };
      setGetProtectedRouteResponse({
        result,
      });
    } else {
      setGetProtectedRouteResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${protectedRouteURL}: ${error}`);
    setGetProtectedRouteResponse({
      success: false,
      errorMessage: "Error fetching from accessProtectedRoute.",
    });
  }
};


export const testBackend_API = async (setTestBackendResponse) => {
  console.log(`This is testBackend_API.`);

  try {
    const data = await fetchWithRedirect(testRouteURL, {
      method: "GET",
    });

    if (data === null || typeof data === "undefined") {
      console.error("testBackend response is undefined.");
      setTestBackendResponse({
        success: false,
        errorMessage: "testBackend response is undefined.",
      });
    } else {
      setTestBackendResponse(data);
    }
  } catch (error) {
    console.error(`Error fetching from ${testRouteURL}: ${error}`);
    setTestBackendResponse({
      success: false,
      errorMessage: "Error fetching from testBackend.",
    });
  }
};

export const createRoom_API = async (
  meetingID,
  meetingName,
  moderatorPW,
  attendeePW,
  setCreateRoomResponse
) => {
  let result;

  console.log(
    `This is createRoom_API meetingID: ${meetingID}, meetingName: ${meetingName}, moderatorPW: ${moderatorPW}, attendeePW: ${attendeePW}.`
  );

  createRoom_local(meetingID, meetingName, moderatorPW, attendeePW).then(
    (data) => {
      if (data === null || typeof data === "undefined") {
        console.error("createRoom response is undefinied.");
        result.returncode = "FAILED";
        result.errorMessage = "createRoom response is undefinied.";
      } else {
        result = data;
      }
      setCreateRoomResponse(result);
    }
  );
};

export const endMeeting_API = async (
  meetingID,
  moderatorPW,
  setEndMeetingResponse
) => {
  let result;

  console.log(
    `This is endMeeting_API meetingID: ${meetingID}, moderatorPW: ${moderatorPW}.`
  );

  endMeeting_local(meetingID, moderatorPW).then((data) => {
    if (data === null || typeof data === "undefined") {
      console.error("endMeeting response is undefinied.");
      result.returncode = "FAILED";
      result.errorMessage = "endMeeting response is undefinied.";
    } else {
      result = data;
    }
    setEndMeetingResponse(result);
  });
};
