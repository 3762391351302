import emojiFlags from "emoji-flags";
import countries from "i18n-iso-countries";
import ar from "i18n-iso-countries/langs/ar.json";
import { getCountryCallingCode } from "libphonenumber-js";
import { getCodeList } from "country-list";
import { convertToIndianNumerals } from "./utilities";
countries.registerLocale(ar);

export const TOKEN_NAME = "ediscoolT";


const allCountryCodes = Object.keys(getCodeList()).map((code) =>
  code.toUpperCase()
);





export const countries_en = allCountryCodes
  .map((code) => {
    try {
      const callingCode = getCountryCallingCode(code);
      return {
        value: code,
        label: `${emojiFlags.countryCode(code).emoji} ${
          emojiFlags.countryCode(code).name
        } (+${callingCode})`,
      };
    } catch (error) {
      //console.error(`Could not get calling code for country: ${code}`);
      return undefined;
    }
  })
  .filter(Boolean);


export const countries_ar = allCountryCodes
  .map((code) => {
    try {
      const callingCode = getCountryCallingCode(code);
      const indianCallingCode = convertToIndianNumerals(callingCode);
      const countryNameInArabic = countries.getName(code, "ar");
      return {
        value: code,
        label: `${
          emojiFlags.countryCode(code).emoji
        } ${countryNameInArabic} (+${indianCallingCode})`,
      };
    } catch (error) {
      //console.error(`Could not get calling code for country: ${code}`);
      return undefined;
    }
  })
  .filter(Boolean);

export const languages = [
  { value: "ar", label: emojiFlags.countryCode("OM").emoji + " - ع" },
  { value: "en", label: emojiFlags.countryCode("US").emoji + " - Eng." },
];
