import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
//import { fetchAuthenticationStatusAPI } from "../api/api.js";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { fetchLMSCoursesAPI, fetchUserCoursesAPI } from "../api/api";
import { useAuthenticationContext } from "./AuthenticationContext";

//create empty context
const CoursesContext = createContext({});

const generalCoursesStorage = "generalCourses";
const userCoursesStorage = "userCourses";

export function useCoursesContext() {
  return useContext(CoursesContext);
}

export function CoursesContextProvider({ children }) {
  const { authenticationStatus } = useAuthenticationContext();
  const [generalCourses, setGeneralCourses] = useSessionStorage(
    generalCoursesStorage,
    []
  );
  const [userCourses, setUserCourses] = useLocalStorage(userCoursesStorage, []);

  const [getGeneralCoursesResponse, setGetGeneralCoursesResponse] =
    useState(null);

  const [getUserCoursesResponse, setGetUserCoursesResponse] = useState(null);

  // Sync with session storage on component mount
  useEffect(() => {
    const storedGeneralCourses = sessionStorage.getItem(generalCoursesStorage);
    const storedUserCourses = localStorage.getItem(userCoursesStorage);
    if (storedGeneralCourses) {
      setGeneralCourses(JSON.parse(storedGeneralCourses));
    }
    if (storedUserCourses) {
      setUserCourses(JSON.parse(storedUserCourses));
    }
  }, [setGeneralCourses, setUserCourses]);

  //check if get-general-courses response is received
  useEffect(() => {
    if (getGeneralCoursesResponse) {
      console.log(`>>> generalCoursesReponse is received <<<<`);
      if (getGeneralCoursesResponse.success) {
        console.log("getGeneralCourses is valid.");
        setGeneralCourses(getGeneralCoursesResponse.courses);
      } else {
        console.log(
          "Error in getting general courses from backend >> do nothing"
        );
      }
      setGetGeneralCoursesResponse(null);
    }
  }, [getGeneralCoursesResponse]);

  //check if get-user-courses response is received
  useEffect(() => {
    if (getUserCoursesResponse) {
      console.log(`>>> userCoursesResponse is received <<<<`);
      if (getUserCoursesResponse.success) {
        console.log("userCoursesResponse is valid.");
        setUserCourses(getUserCoursesResponse.courses);
      } else {
        console.log("Error in getting user courses from backend >> do nothing");
      }
      setGetUserCoursesResponse(null);
    }
  }, [getUserCoursesResponse]);

  //get general courses from backend after component is mounted
  useEffect(() => {
    fetchLMSCoursesAPI(setGetGeneralCoursesResponse);
  }, []);

  //get user's courses whenever authenticationStatus changes
  useEffect(() => {
    if (authenticationStatus.authenticated) {
      console.log(
        `Getting courses in which user (${authenticationStatus.username}) is enrolled in.`
      );
      fetchUserCoursesAPI(setGetUserCoursesResponse);
    } else {
      setUserCourses([]);
    }
  }, [authenticationStatus]);

  const findCourseByShortName = (shortname) => {
    return generalCourses.find((course) => course.shortname === shortname);
  };

  return (
    <CoursesContext.Provider
      value={{
        generalCourses,
        userCourses,
        findCourseByShortName,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
}
