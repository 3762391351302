import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import defaultLogo from "../../media/logo.svg";
import SingleSelect from "../common/SingleSelect";
//import { languages } from "../utilities/common";
import "./navbar.css";
import "../../styles.css";
import "@fortawesome/fontawesome-free/css/all.css"; // Import the Font Awesome CSS
//import { useRestaurantInfoContext } from "../context/RestaurantInfoContext";
//import { useSystemSettingsContext } from "../context/SystemSettingsContext";
import { useAuthenticationContext } from "../../context/AuthenticationContext";
import MyButton from "../MyButton";

function Navbar() {
  const { t, i18n } = useTranslation(["navbar"]);
  //const { restaurantNameInContext } = useRestaurantInfoContext();
  //const { systemSettings, menuType, MENU_TYPES } = useSystemSettingsContext();
  const { logoutUser, authenticationStatus, USER_ROLES } =
    useAuthenticationContext();

  //const [restaurantLogo, setRestaurantLogo] = useState(null);
  //const [isCartOpen, setIsCartOpen] = useState(false);
  const [click, setClick] = useState(false);
  //const [noNavbar, setNoNavbar] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  //const location = useLocation();

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  //reload item when authentication status changes.
  /*useEffect(() => {
    console.log(
      `Navbar: authenticationStatus: ${JSON.stringify(authenticationStatus)}`
    );
  }, [authenticationStatus]);*/

  useEffect(() => {
    if (selectedLanguage) {
      console.log(`selectedLanguage: ${JSON.stringify(selectedLanguage)}`);
      i18next.changeLanguage(selectedLanguage ? selectedLanguage : "en");
      //i18next.changeLanguage("ar");
    }
  }, [selectedLanguage]);

  const handleLogout = () => {
    console.log("Logging out user...");
    logoutUser();
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to={"/"} onClick={closeMobileMenu}>
          <img className="navbar-logo" src={defaultLogo} alt="Logo" />
        </Link>

        <div className="navbar-lang-and-menu">
          {/*<Link className="navbar-lang" onClick={closeMobileMenu}>
            <SingleSelect
              list={languages}
              initialLabel={languages[1].label}
              setSelectedOption={setSelectedLanguage}
            />
          </Link>*/}

          <div className="mobile-menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul
            className={click ? "nav-menu active" : "nav-menu"}
            lang={i18n.language}
          >
            <>
              <li className="nav-item">
                <Link to={`/`} className="nav-links" onClick={closeMobileMenu}>
                  {t("Home")}
                </Link>
              </li>
              {authenticationStatus.authenticated &&
                authenticationStatus.role === USER_ROLES.ADMIN && (
                  <li className="nav-item">
                    <Link
                      to={`/registeration`}
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      {t("Registeration")}
                    </Link>
                  </li>
                )}
              {/*<li className="nav-item">
                <Link
                  to={`/logintest`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("LoginTest")}
                </Link>
              </li>*/}
              <li className="nav-item">
                <Link
                  to={`/playground`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("Playground")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/lms`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("LMS")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/about`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("About Us")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/contactus`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("Contact Us")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`/signup`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {t("Get Started")}
                </Link>
              </li>
              {!authenticationStatus.authenticated && (
                <>
                  <li className="nav-item" onClick={closeMobileMenu}>
                    <MyButton
                      variant={"primary"}
                      title={"Login"}
                      onClickHandler={() => {
                        navigate("/login");
                      }}
                      disabled={false}
                    />
                  </li>
                  <li className="nav-item" onClick={closeMobileMenu}>
                    <MyButton
                      variant={"secondary"}
                      title={"Sign Up"}
                      onClickHandler={() => {
                        navigate("/signup");
                      }}
                      disabled={false}
                    />
                  </li>
                </>
              )}
              {authenticationStatus.authenticated && (
                <li className="nav-item" onClick={closeMobileMenu}>
                  <MyButton
                    variant={"primary"}
                    title={"Logout"}
                    onClickHandler={handleLogout}
                    disabled={false}
                  />
                </li>
              )}
            </>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
