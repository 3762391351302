import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import TextField from "@mui/material/TextField";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import MyAutoComplete from "../components/common/MyAutoComplete";
import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import {
  checkNumberValidity,
  checkPhoneNumberValidity,
  checkEmailValidity,
  checkNameValidity,
  checkPersonNameValidity,
  containsIndianNumerals,
  convertToArabicNumerals,
} from "../commons/utilities";

import { countries_ar, countries_en } from "../commons/constants";
import { useAppearanceContext } from "../context/AppearanceContext";
import { verifyEmailAndPhoneAPI } from "../api/api";
import classes from "./page.module.css";
import signUpClasses from "./signUp.module.css";

function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["getStarted"]);
  const { isMobile, isSmallerScreen } = useAppearanceContext();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [validFullPhoneNumber, setValidFullPhoneNumber] = useState("");
  const [isInvalidPhoneNum, setIsInvalidPhoneNum] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isInvalidCountry, setIsInvalidCountry] = useState(false);
  const [invalidCountryMessage, setInvalidCountryMessage] = useState("");

  const [verifyEmailAndPhoneResponse, setVerifyEmailAndPhoneResponse] =
    useState(null);

  //for testing, console.log validPhoneNumber whenever it changes.
  useEffect(() => {
    console.log(`validFullPhoneNumber: ${validFullPhoneNumber}`);
  }, [validFullPhoneNumber]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setUsername(queryParams.get("username") || ""); // Default to empty string if null
    setEmail(queryParams.get("email") || "");
  }, [location]);

  //check if verification response is received
  useEffect(() => {
    if (verifyEmailAndPhoneResponse) {
      if (verifyEmailAndPhoneResponse.success) {
        console.log(`Successfully verified email and phone number.`);
        setModalInfoMessage(
          `Thank you ${username} for verifying your email and phone number. You can now login and use EdIsCool.`
        );
        setShowModalInfo(true);
      } else {
        console.log(`Failed to verify email and phone number.`);
        setModalAlertMessage(
          `Failed to verify email and phone number. Error: ${
            verifyEmailAndPhoneResponse.errorMessage || "undefined"
          }. Please try again later.`
        );
        setShowModalAlert(true);
      }
      setIsBusy(false);
      setVerifyEmailAndPhoneResponse(null);
    }
  }, [verifyEmailAndPhoneResponse]);

  const handleAccountPhoneNumber = (e) => {
    console.log(
      `phone num: ${e.target.value}, country code: ${
        selectedCountry
          ? getCountryCallingCode(selectedCountry.value)
          : "undefined"
      }`
    );
    if (checkPhoneNumberValidity(e.target.value)) {
      setPhoneNumber(e.target.value);
      setIsInvalidPhoneNum(false);
    } else {
      console.error("phone num. is invalid.");
      setIsInvalidPhoneNum(true);
    }
  };

  const handleVerifyButton = (e) => {
    e.preventDefault();

    //

    // Check if the country or phone number is null or empty
    if (!selectedCountry || !phoneNumber) {
      if (!selectedCountry) {
        setIsInvalidCountry(true);
        setInvalidCountryMessage(t("helperMessageCountry"));
      }
      if (!phoneNumber) setIsInvalidPhoneNum(true);

      let message = `Please fill in all required fields.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    // Check if the phone number is valid
    if (isInvalidPhoneNum) {
      let message = `Phone number is invalid.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    //change phone number (if in hindu-arabic numerals to arabic numerals)
    let normalizedPhoneNumber;
    if (containsIndianNumerals(phoneNumber)) {
      console.log(`phone number contains indian numerals`);
      normalizedPhoneNumber = convertToArabicNumerals(phoneNumber);
      console.log(`converted phone number: ${normalizedPhoneNumber}`);
    } else {
      console.log(`phone number does not contain indian numerals`);
      normalizedPhoneNumber = phoneNumber;
    }

    let fullPhoneNumber = `+${getCountryCallingCode(
      selectedCountry.value
    )}${normalizedPhoneNumber}`;
    console.log(`full phone number: ${fullPhoneNumber}`);
    let parsedPhoneNumber = parsePhoneNumberFromString(
      fullPhoneNumber,
      selectedCountry.value
    );
    // Check if the phone number is valid
    if (!parsedPhoneNumber?.isValid()) {
      let message = `Phone number is invalid.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    console.log(`valid phone number proceed...`);

    console.log(`full phone number: ${fullPhoneNumber}`);

    setValidFullPhoneNumber(fullPhoneNumber);

    //////////////////////

    //check if reCAPTCHA is verified
    /*const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      setModalAlertMessage("Please tick the checkbox of the reCAPTCHA!");
      setShowModalAlert(true);
      return;
    }

    console.log("submit reCAPTCHA to server for verification.");

    verifyCaptchaAPI(captchaValue, setCaptchaVerificationResponse);*/

    console.log(
      `*** All parameters are valid. Verify captacha and then submit sign up request...`
    );

    submitVerificationRequest(username, email, fullPhoneNumber);
    //submitVerificationRequest();
  };

  const submitVerificationRequest = (username, email, phoneNumber) => {
    console.log(
      `This is submitVerificationRequest function. Username: ${username}, email: ${email}, phone: ${phoneNumber}`
    );
    verifyEmailAndPhoneAPI(
      username,
      email,
      phoneNumber,
      setVerifyEmailAndPhoneResponse
    );

    setIsBusy(true);
    setSelectedCountry(null);
    setPhoneNumber("");
    setValidFullPhoneNumber("");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={signUpClasses.getStartedContainer}>
          <h1>{t("Verification")}</h1>
          <p>
            Thank you ({username}) for signing up. Please re-enter your phone
            number and press on verify.
          </p>
          <form
            className={
              isSmallerScreen
                ? signUpClasses.getStartedForm_mobile
                : signUpClasses.getStartedForm
            }
            onSubmit={handleVerifyButton}
          >
            <div className={signUpClasses.getStartedElement}>
              <MyAutoComplete
                id="country"
                list={i18n.language === "ar" ? countries_ar : countries_en}
                title={t("country")}
                setSelectedOption={(value) => {
                  setSelectedCountry(value);
                  setIsInvalidCountry(false);
                }}
                errorState={isInvalidCountry}
                errorMessage={invalidCountryMessage}
                isNull={!selectedCountry}
                required
              />
            </div>
            <div className={signUpClasses.getStartedElement}>
              <TextField
                type="text"
                id="phoneNumber"
                label={t("phoneNumber")}
                className={signUpClasses.input_element}
                autoComplete="off"
                onChange={handleAccountPhoneNumber}
                value={phoneNumber}
                error={isInvalidPhoneNum}
                helperText={
                  isInvalidPhoneNum ? t("helperMessagePhoneNumber") : null
                }
                required
              />
            </div>
          </form>
          {/*<div className={classes.button_div}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            />
          </div>*/}
          <div className={signUpClasses.button_div}>
            <MyButton
              variant={"primary"}
              onClickHandler={handleVerifyButton}
              title={t("Verify")}
            />
          </div>
        </div>

        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => {
            setShowModalAlert(false);
            
          }}
        />

        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => {
            setShowModalInfo(false);
            navigate("/login");
          }}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default VerifyEmail;
