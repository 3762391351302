import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
//import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetPasswordAPI } from "../api/api";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";

import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import {
  checkPasswordComplexity,
  checkEmailValidity,
  hashPassword,
} from "../commons/utilities";
import classes from "./page.module.css";
import "./login.css";

function ResetPassword() {
  const { userRef } = useRef("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //const restaurantNameFromURL = searchParams.get("res");
  //const userNameFromURL = searchParams.get("n");
  const tokenFromURL = searchParams.get("token");

  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRPassword, setShowRPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [rpassword, setRPassword] = useState(null);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidRPassword, setIsInvalidRPassword] = useState(false);

  const [resetPasswordResponse, setResetPasswordResponse] = useState(null);
  const [showSuccessfullMessage, setShowSuccessfullMessage] = useState(false);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (resetPasswordResponse) {
      setIsBusy(false);
      console.log(
        `Got a new resetPasswordResponse: ${JSON.stringify(
          resetPasswordResponse
        )}`
      );
      if (resetPasswordResponse.success) {
        setShowSuccessfullMessage(true);
      } else {
        setShowSuccessfullMessage(false);
        let message = `Unable to reset password.
        Error message: ${resetPasswordResponse.errorMessage || "Unknown"}.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [resetPasswordResponse]);

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault();
    console.log(
      `Check validity of form inputs, then call the API to reset password......`
    );

    //check validity of restaurantName, user, email
    if (!email || !checkEmailValidity(email)) {
      setIsInvalidEmail(true);
      console.log("Invalid email, returning...");
      return;
    }

    //check validity of form inputs
    if (!password || !rpassword) {
      setModalAlertMessage("Enter valid password.");
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }
    if (password !== rpassword) {
      setModalAlertMessage("Passwords do not match.");
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }

    if (!checkPasswordComplexity(password)) {
      setModalAlertMessage(
        "Password must contain at least 8 characters, including uppercase, lowercase, numbers and special characters."
      );
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }

    console.log(`all parameters are valid, calling the API...`);

    try {
      let kilma = hashPassword(password);
      resetPasswordAPI(
        email,
        tokenFromURL,
        password,
        kilma,
        setResetPasswordResponse
      );
      setIsBusy(true);
    } catch (error) {
      let message = `Error in processing request.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
    }

    setEmail("");
    setPassword("");
    setRPassword("");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className="login_container">
          <p className={classes.titleText}>Reset Your Password</p>
          {!showSuccessfullMessage ? (
            <form className="login_form" onSubmit={handleSubmitResetPassword}>
              <div className="login_element">
                <TextField
                  type="text"
                  id="email"
                  className="input_element"
                  label="Email"
                  autoComplete="off"
                  onChange={(e) => {
                    setIsInvalidEmail(false);
                    setEmail(e.target.value);
                  }}
                  value={email}
                  error={isInvalidEmail}
                  helperText={isInvalidEmail ? "Invalid Email" : ""}
                  required
                />
              </div>
              <div className="login_element">
                <TextField
                  className="input_element"
                  id="password"
                  label="New Password"
                  rows={1}
                  type={showPassword ? "text" : "password"}
                  value={password ? password : ""}
                  error={isInvalidPassword}
                  onChange={(e) => {
                    setIsInvalidPassword(false);
                    setPassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          onClick={() => {
                            setIsInvalidPassword(false);
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <div className="login_element">
                <TextField
                  className="input_element"
                  id="r_password"
                  label="Re-enter Password"
                  rows={1}
                  type={showRPassword ? "text" : "password"}
                  value={rpassword ? rpassword : ""}
                  error={isInvalidRPassword}
                  onChange={(e) => {
                    setIsInvalidRPassword(false);
                    setRPassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div onClick={() => setShowRPassword(!showRPassword)}>
                          {showRPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>

              <div className="button_div">
                <MyButton
                  variant={"primary"}
                  onClickHandler={handleSubmitResetPassword}
                  title={"Reset Password"}
                />
              </div>
            </form>
          ) : (
            <div className="login_form">
              <div className="login_element">
                Password has been successfully reset. Learn On!
              </div>
              <div className="button_div">
                <a className={classes.importantText} href="/login">
                  Login Now!
                </a>
              </div>
            </div>
          )}
        </div>

        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => setShowModalAlert(false)}
        />
        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => setShowModalInfo(false)}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default ResetPassword;
