import React from "react";
import "../../styles.css";

function Footer() {
  return (
    <div className="footer">
      <span>EdIsCool &copy; 2024. </span>
      <span>{`(${process.env.REACT_APP_COMPILATION_TIME})`}</span>
      <span className="footer-secondary">
        All Rights Reserved For RyadaTech. LLC, Oman
      </span>
    </div>
  );
}

export default Footer;
